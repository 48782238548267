<template>
  <!-- <v-container> -->
  <v-container fluid>
    <BackButton :handler="gotoMembership"/>
    <v-container fluid no-gutters style="max-width: 95% !important;" class="form_container">
      <v-form ref="form" v-model="valid">

        <div class="d-flex justify-space-between">
          <h3 class="text-base font-semibold black-text ml-2">Membership Details</h3>
          <v-switch
              :false-value="0"
              :true-value="1"
              v-model="membership.is_public"
              class="mx-4 my-0 v-input--reverse"
              dense
              hide-details
          >
            <template #label>
              Enable Online
            </template>
          </v-switch>
        </div>
        <v-card class="rounded shadow-0 bordered mt-2">
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="7" sm="12" class="pr-8" >
                <v-row dense>
                  <v-col cols="12" sm="12" md="12">
                    <label for="">Name*</label>
                    <v-text-field
                        v-model="membership.name"
                        outlined
                        required
                        :rules="[(v) => !!v || 'Name is required']"
                        class="q-text-field shadow-0"
                        dense
                        validate-on-blur
                        hide-details="hide-details"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <label for="">Name (AR)</label>
                    <v-text-field
                        v-model="membership.ar_name"
                        outlined
                        required
                        class="q-text-field shadow-0"
                        dense
                        validate-on-blur
                        hide-details="hide-details"

                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <label for="">Service*</label>
                    <v-select
                        v-model="membership.venue_service_id"
                        :items="venueServices"
                        item-text="name"
                        item-value="venue_service_id"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        multiple
                        :rules="[(v) => v.length > 0 || 'Service is required']"
                        required
                        class="q-text-field shadow-0"
                        dense
                        validate-on-blur
                        hide-details="auto"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggleSelect()">
                          <v-list-item-action>
                            <v-icon
                                :color="
                              membership.venue_service_id.length > 0
                                ? 'teal darken-4'
                                : ''
                            "
                            >{{ getServiceIcon() }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template
                          v-if="
                        membership.venue_service_id.length ==
                          venueServices.length
                      "
                          v-slot:selection="{ index }"
                      >
                        <span v-if="index == 0">All Services</span>
                      </template>
                      <template v-else v-slot:selection="{ item, index }">
                        <span v-if="index == 0">{{ item.name }}</span>
                        <span v-if="index == 1">, {{ item.name }}</span>
                        <span v-if="index == 2">, ...</span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col md="6">
                    <label for="">Membership card image</label>
                    <v-file-input
                        v-model="membership.file"
                        class="q-text-field shadow-0"
                        dense
                        validate-on-blur
                        prepend-icon=""
                        :placeholder="
                      `${membership.image_path ? 'Change' : 'Select'}`
                    "

                        outlined
                        show-size
                        accept=".jpeg,.png,.jpg"
                        hide-details="auto"
                    >
                      <template v-slot:prepend-inner>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                                color="cyan"
                                v-if="membership.card_image_path"
                                @click="openFile(membership.card_image_path)"
                                v-on="on"
                            >
                              mdi-download-box
                            </v-icon>
                            <v-icon v-else v-on="on">mdi-image</v-icon>
                          </template>
                          <span v-if="membership.card_image_path">
                          Download image</span
                          >
                          <span v-else>Upload membership card image</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                            v-if="index == 0"
                            color="cyan accent-4"
                            dark
                            label
                            small
                        >
                        <span style="width: 120px" class="text-truncate">{{
                            text
                          }}</span>
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="4"
                      md="6"
                      v-if="parentCarousels.length > 0"
                  >
                    <label for="">Partner Categories</label>
                    <v-select
                        v-model="membership.carousel_id"
                        :items="parentCarousels"
                        item-text="title"
                        item-value="id"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        multiple
                        class="q-text-field shadow-0"
                        dense
                        validate-on-blur
                        hide-details="hide-details"

                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggleSelectCarousel()">
                          <v-list-item-action>
                            <v-icon
                                :color="
                              membership.carousel_id.length > 0
                                ? 'teal darken-4'
                                : ''
                            "
                            >{{ getServiceIcon() }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template
                          v-if="
                        membership.carousel_id.length == parentCarousels.length
                      "
                          v-slot:selection="{ index }"
                      >
                        <span v-if="index == 0">All Categories</span>
                      </template>
                      <template v-else v-slot:selection="{ item, index }">
                        <span v-if="index == 0">{{ item.title }}</span>
                        <span v-if="index == 1">, {{ item.title }}</span>
                        <span v-if="index == 2">, ...</span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                      cols="12"
                  >
                    <div>
                      <v-switch
                          :false-value="0"
                          :true-value="1"
                          v-model="membership.has_date_restriction"
                          class="my-4"
                          dense
                          hide-details="auto"
                      >
                        <template #label>
                          Enable Date Restriction
                        </template>
                      </v-switch>
                    </div>
                    <div class="d-flex gap-x-4" v-if="membership.has_date_restriction == 1">
                      <div class="w-full">
                        <label for="">Start Date</label>
                        <date-field
                            v-model="membership.start_date"
                            :dense="true"
                            :hide-details="true"
                            class-name="q-text-field shadow-0"
                            label=""
                            :backFill="
                              checkBackfillPermission($modules.memberships.management.slug)
                            "
                        >
                        </date-field>
                      </div>
                      <div class="w-full">
                        <label for="">End Date</label>
                        <date-field
                            v-model="membership.end_date"
                            :dense="true"
                            :hide-details="true"
                            class-name="q-text-field shadow-0"
                            label=""
                            :backFill="
                              checkBackfillPermission($modules.memberships.management.slug)
                            "
                        >
                        </date-field>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col  md="5" sm="12" lg="5"  >


                <div style="height: 200px">
                  <image-uploader
                      @upload="
                  (data) => {
                    membership.image = data;
                  }
                "
                      @remove="
                  () => {
                    membership.image = null;
                  }
                "
                      :image_path="membership.image_path"
                      defaultImage="membership"
                      messagePosition="inside"
                      text="Membership Image"
                      :height="150"
                  ></image-uploader>
                </div>


                <image-upload v-if="1==4"
                                @upload="
                  (data) => {
                    membership.image = data;
                  }
                "
                                @remove="
                  () => {
                    membership.image = null;
                  }
                "
                                :image_path="membership.image_path"
                                defaultImage="membership"
                                messagePosition="inside"
                                text="Membership Image"
                                :height="240"
                ></image-upload>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
        <v-row class="mt-4" dense>
          <v-col cols="12">
            <h3 class="text-base font-semibold black-text ml-1">Membership Description</h3>
          </v-col>
          <v-col cols="12">
            <text-editor
                @complete="
                  (val) => {
                    setDescriptionContent(val);
                  }
                "
                :message="membership_desc"
            />
          </v-col>
          <v-col cols="12">
            <h3 class="text-base font-semibold black-text ml-1">Membership Description(AR)</h3>
          </v-col>
          <v-col cols="12">
            <text-editor
                @complete="
                  (val) => {
                    setArDescriptionContent(val);
                  }
                "
                :message="ar_membership_desc"
            />
          </v-col>
        </v-row>




        <v-row dense class="mt-4">
          <v-col cols="12">
            <h3 class="text-base font-semibold black-text ml-1">Package Details</h3>
          </v-col>
          <v-col cols="12" md="12">
            <v-card
                v-for="(duration, di) in membership.durations"
                :class="`rounded shadow-0  bordered ${di>0 ? 'mt-5':''}`"
                :key="di"
            >
              <v-tooltip bottom v-if="membership.durations.length > 1">
                <template v-slot:activator="{ on, attrs }">

                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      v-if="membership.durations.length > 1"
                      fab
                      dark
                      x-small
                      absolute
                      top
                      right
                      color="dark-grey"
                      style="margin-right: -20px"
                      @click="warningDurationDelete(di)"
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </template>
                Delete
              </v-tooltip>

              <v-card-text>
                <v-row class="border-bottom pb-3" dense>
                  <v-col md="12" lg="12" sm="12">
                    <h3 class="text-base black-text">{{di+1}}. Package Duration </h3>
                  </v-col>
                </v-row>


                <v-row dense>

                  <v-col md="2">
                    <label for="">Year</label>
                    <v-text-field
                        v-model="duration.year"
                        outlined
                        required
                        @change="installmentPlansCheck(di)"
                        class="q-text-field shadow-0"
                        dense
                        hide-details="auto"

                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <label for="">Month</label>
                    <v-text-field
                        v-model="duration.month"
                        outlined
                        required
                        @change="installmentPlansCheck(di)"
                        class="q-text-field shadow-0"
                        dense
                        hide-details="auto"

                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <label for="">Days</label>
                    <v-text-field
                        v-model="duration.day"
                        outlined
                        required
                        @change="installmentPlansCheck(di)"
                        class="q-text-field shadow-0"
                        dense
                        hide-details="auto"

                    ></v-text-field>
                  </v-col>
                  <v-col md="6" class="flex-column  align-center">
                    <p class="align-center mt-8 font-italic font-medium text-sm blue-text">Membership Duration {{ duration.year != null ? `${duration.year} Year(s)` : "" }}
                      {{ duration.month != null ? `${duration.month} Month(s)` : "" }}
                      {{ duration.day != null ? `${duration.day} Day(s)` : "" }}</p>

                  </v-col>
                </v-row>
                <div class="titles" style="font-weight:normal; font-size:12px">
                  Note: Payment plan only applicable for Yearly memberships
                </div>


                <template v-for="(membershipPackage, pi) in duration.packages">
                  <v-row dense class="mt-4 border-bottom p-2 " :key="`${pi}--uu`" style="background-color: #00b0af10">
                    <v-col cols="12">
                      <h3 class="text-base black-text font-bold ">{{pi+1}}. General Information </h3>
                    </v-col>
                  </v-row>
                  <v-row  :key="pi" dense class="mt-2">


                    <v-col lg="2">
                      <label for="">Name*</label>
                      <v-text-field
                          required
                          hide-details="auto"
                          v-model="membershipPackage.name"
                          outlined
                          :rules="[(v) => !!v || 'Package Name is required']"
                          class="q-text-field shadow-0"
                          dense
                          validate-on-blur

                      ></v-text-field>
                    </v-col>
                    <v-col lg="2">
                      <label for="">Name (Ar)</label>

                      <v-text-field
                          hide-details="auto"
                          v-model="membershipPackage.ar_name"
                          outlined
                          class="q-text-field shadow-0"
                          dense
                          validate-on-blur
                      ></v-text-field>
                    </v-col>
                    <v-col md="2">
                      <label for="">Tax*</label>
                      <v-select
                          hide-details="auto"
                          v-model="membershipPackage.tax_type_id"
                          item-value="value"
                          item-text="text"
                          :items="taxTypes"
                          outlined
                          @change="taxChange(di, pi)"
                          :menu-props="{ bottom: true, offsetY: true }"
                          :rules="[(v) => !!v || 'Tax is required']"
                          class="q-autocomplete shadow-0"
                          dense
                          validate-on-blur
                      ></v-select>
                    </v-col>
                    <v-col lg="2">
                      <label for="">Type*</label>
                      <v-select
                          required
                          hide-details="auto"
                          v-model="membershipPackage.membership_type"
                          outlined
                          :menu-props="{ bottom: true, offsetY: true }"
                          :items="packageTypes"
                          @change="setPackageTypeChange(di, pi)"
                          item-text="name"
                          item-value="type"
                          :rules="[(v) => !!v || 'Type is required']"
                          class="q-autocomplete shadow-0"
                          dense
                          validate-on-blur
                      ></v-select>
                    </v-col>

                    <v-col md="2" v-if="membershipPackage.membership_type == 'G'">
                      <label for="">Max No. of Members*</label>
                      <v-text-field
                          hide-details="auto"
                          required
                          :rules="[
                  (v) => !!v || 'Max No. of Members is required',
                  (v) => !isNaN(v) || 'Max Number must be Number',
                ]"
                          v-model="membershipPackage.members_limit"
                          outlined
                          class="q-text-field shadow-0"
                          dense
                          validate-on-blur
                      ></v-text-field>
                    </v-col>
                    <v-col md="2" v-if="membershipPackage.membership_type == 'G'">
                      <label for="">Additional Members*</label>
                      <v-select
                          required
                          hide-details="auto"
                          v-model="membershipPackage.additional_members"
                          :items="['Yes', 'No']"
                          outlined
                          :menu-props="{ bottom: true, offsetY: true }"
                          class="q-autocomplete shadow-0"
                          dense
                          validate-on-blur
                      ></v-select>
                    </v-col>
                    <v-col
                        lg="2"
                        v-if="
                membershipPackage.membership_type == 'G' &&
                  membershipPackage.additional_members == 'Yes'
              "
                    >
                      <label for="">Addition Price*</label>

                      <v-text-field
                          :suffix="currencyCode"
                          hide-details="auto"
                          required
                          v-model="membershipPackage.additional_price"
                          :rules="priceRules"
                          outlined
                          class="q-autocomplete shadow-0"
                          dense
                          validate-on-blur
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                        md="4"
                        v-if="
                membershipPackage.membership_type == 'G' &&
                  membershipPackage.additional_members == 'Yes'
              "
                    >
                      <v-switch
                          :false-value="0"
                          :true-value="1"
                          label="Include in promotion discount"
                          v-model="membershipPackage.allow_additional_promo"
                          class="mx-4 my-0 mt-6"
                      ></v-switch>
                    </v-col>
                    <v-col
                        v-if="duration.packages.length > 1"
                        md="1"
                        :style="membershipPackage.type == 'g' && 'margin-top:-50px;'"
                        class="del_icon"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <DeleteIcon  @click="warningPackageRemove(di, pi)"  style="cursor: pointer" v-bind="attrs" class="mt-6"
                                       v-on="on"    />

                        </template>
                        Delete
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12">
                      <v-row v-if="isEnableDctErp">
                        <v-col cols="12" sm="6" md="3">
                          <label for="">Project Number *</label>
                          <v-text-field
                              outlined
                              v-model="membershipPackage.project_no"
                              hide-details="auto"
                              placeholder="Project Number"
                              required
                              :rules="[(v) => !!v || 'Project number is required']"
                              class="q-text-field shadow-0"
                              dense
                              validate-on-blur
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <label for="">Task Name *</label>
                          <v-text-field
                              outlined
                              v-model="membershipPackage.task_name"
                              hide-details="auto"
                              placeholder="Task Name"
                              required
                              :rules="[(v) => !!v || 'Task name is required']"
                              class="q-text-field shadow-0"
                              dense
                              validate-on-blur
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <label for="">Gl Code *</label>
                          <v-text-field
                              outlined
                              v-model="membershipPackage.gl_code"
                              hide-details="auto"
                              placeholder="Gl Code"
                              required
                              :rules="[(v) => !!v || 'Gl Code is required']"
                              class="q-text-field shadow-0"
                              dense
                              validate-on-blur
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <label for="">Transaction Type*</label>

                          <v-text-field
                              outlined
                              v-model="membershipPackage.transaction_type"
                              hide-details="auto"
                              placeholder="Transaction Type"
                              required
                              :rules="[(v) => !!v || 'Transaction Type is required']"
                              class="q-text-field shadow-0"
                              dense
                              validate-on-blur
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row dense class="mt-4 border-bottom pb-3" :key="`${di}_${pi}_div`">
                    <v-col cols="12">
                      <h3 class="text-base font-bold black-text">Pricing</h3>
                    </v-col>
                  </v-row>


                  <v-row class="mt-4"  dense  v-for="(pricing, pri) in membership.durations[di].packages[pi]
              .pricing"
                         :key="`${di}_${pi}_${pri}_pricing`">
                    <v-col cols="12" sm="6" md="3">
                      <label for="">Payment Plan*</label>

                      <v-select
                          v-model="pricing.installment_plan"
                          item-value="id"
                          item-text="name"
                          :items="getInstallmentOptions(di)"
                          outlined
                          hide-details="auto"
                          :disabled="!membership.durations[di].packages[pi].tax_type_id"
                          :readonly="
                    membership.durations[di].installment_check === false
                  "
                          @change="priceChange(di, pi, pri, 'pre')"
                          :menu-props="{ bottom: true, offsetY: true }"
                          :rules="[(v) => !!v || 'Plan is required']"
                          class="q-autocomplete shadow-0"
                          dense
                          validate-on-blur
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="6" md="2">
                      <label for="">Price (Pre Tax)*</label>

                      <v-text-field
                          required
                          hide-details="auto"
                          v-model="pricing.pre_tax_price"
                          outlined
                          @change="priceChange(di, pi, pri, 'pre')"
                          :suffix="currencyCode"
                          :disabled="!membership.durations[di].packages[pi].tax_type_id"
                          :rules="[
                    (v) => v>=0 || 'Package Pre tax Price is required',
                    (v) => !isNaN(v) || 'Package Price must be Number',
                  ]"
                          class="q-text-field shadow-0"
                          dense
                          validate-on-blur
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="2">
                      <label for="">Price (Post Tax)*</label>
                      <v-text-field
                          required
                          hide-details="auto"
                          v-model="pricing.total_price"
                          outlined
                          :disabled="!membership.durations[di].packages[pi].tax_type_id"
                          :suffix="currencyCode"
                          @change="priceChange(di, pi, pri, 'post')"
                          :rules="[
                    (v) => v>=0 || 'Package Price is required',
                    (v) => !isNaN(v) || 'Package Price must be Number',
                  ]"
                          class="q-text-field shadow-0"
                          dense
                          validate-on-blur
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <label for="">Total Price per installment*</label>
                      <v-text-field
                          :suffix="currencyCode"
                          v-model="pricing.price_per_installment"
                          outlined
                          readonly
                          hide-details="auto"
                          :rules="[(v) => v>=0  || 'Price per installment is required']"
                          class="q-text-field shadow-0"
                          dense
                          validate-on-blur
                      ></v-text-field>
                    </v-col>
                    <v-col
                        v-if="membership.durations[di].packages[pi].pricing.length > 1"
                        md="1"
                        :style="membershipPackage.type == 'g' && 'margin-top:-50px;'"
                        class="del_icon"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <DeleteIcon  @click="pricingRemovePackage(di, pi, pri)"  style="cursor: pointer" v-bind="attrs" class="mt-6"
                                       v-on="on"   />
                          <v-btn
                              v-bind="attrs" v-if="1==3"
                              v-on="on"
                              color="red"
                              class="mt-1"
                              @click="pricingRemovePackage(di, pi, pri)"
                              fab
                              x-small
                              dark
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        Delete
                      </v-tooltip>
                    </v-col>
                  </v-row>


                  <v-row :key="`${di}_${pi}_button`"  v-if="membership.durations[di].installment_check" dense>
                    <v-col>
                      <p class="text-neon text-base font-medium pointer" @click="newPricing(di, pi)" style="cursor: pointer">+Add Payment Plan</p>
                    </v-col>
                  </v-row>

                  <v-row dense class="mt-4 border-bottom pb-3" :key="`${di}_${pi}_div_h`">
                    <v-col cols="12">
                      <h3 class="text-base font-bold black-text">Package Benefits</h3>
                    </v-col>
                  </v-row>
                  <v-row :key="`${di}_${pi}_package`" class="mt-5 border-bottom" dense>

                    <v-col md="12">
                      <div class="mb-4" v-if="membershipPackage.benefits.length > 0">
                        <benefit-selection
                            outlined
                            v-for="(benefit, bi) in membershipPackage.benefits"
                            :key="benefit.id||bi"
                            :benefitIndex="bi"
                            :durationIndex="di"
                            :packageIndex="pi"
                            :product_type="benefit.product_type"
                            :params="benefit"
                            @update="updateBenefit"
                            @delete="warningBenefitRemove"
                            @duplicate="duplicateBenefit"
                            :product-types="productTypes"
                        >
                        </benefit-selection>
                      </div>

                    </v-col>
                    <v-col cols="12">
                      <p   @click="addProductType( di, pi)" class="text-neon text-base pointer font-medium">+Add Benefit </p>
                    </v-col>

                  </v-row>
                </template>

                <v-row dense class="mt-4">
                  <v-col cols="12">
                    <p class=" text-base text-neon  font-medium pointer" style="cursor: pointer"
                       @click="
                  addPackage(di)
                "

                    >+Add Package</p>
                  </v-col>
                </v-row>
              </v-card-text>

            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn color="teal-color" class="shadow-0"  dark @click="newDurationType">
              + Add Duration Type
            </v-btn>
          </v-col>
        </v-row>


        <v-row>
          <v-spacer></v-spacer>
          <v-btn class="ma-2 white--text blue-color shadow-0" @click="addOrEditmembership"
          >{{ membership.id != null ? "Update" : "Add" }} Membership</v-btn
          >
        </v-row>
      </v-form>
    </v-container>

    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import BenefitSelection from "@/components/Benefit/BenefitSelection.vue";
import TextEditor from "@/components/Marketing/TextEditor";
import DeleteIcon from '@/assets/images/misc/delete-bg-icon.svg';
import ImageUploader from "@/components/Image/ImageUploader.vue";
import BackButton from "@/components/Common/BackButton.vue";

export default {
  components: {
    BackButton,
    ImageUploader,
    TextEditor,
    BenefitSelection,
    DeleteIcon
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes");
    }
    if (this.$route.params.data != null) {
      this.populateEditMembership(atob(this.$route.params.data));
    }
    if (this.$store.getters.getInstallmentPlans.status == false) {
      this.$store.dispatch("loadInstallmentPlans");
    }
    this.loadParentCarousels();
  },
  data() {
    return {
      priceRules: [
        v => !!v || 'Additional price is required',
        v => !isNaN(v) || 'Additional price must be a number',
      ],
      membership_desc: null,
      ar_membership_desc: null,
      membership: {
        is_public: 0,
        name: null,
        ar_name: null,
        file: null,
        venue_service_id: [],
        carousel_id: [],
        description: null,
        ar_description: null,
        start_date:null,
        end_date:null,
        has_date_restriction:0,
        durations: [
          {
            installment_check: false,
            year: null,
            month: null,
            day: null,
            packages: [
              {
                name: null,
                ar_name: null,
                price: null,
                tax_type_id: null,
                membership_type: null,
                benefits: [],
                pricing: [
                  {
                    id: null,
                    installment_plan: 1,
                    price_per_installment: null,
                    pre_tax_price: null,
                    total_price: null,
                  },
                ],
                project_no: null,
                task_name: null,
                gl_code: null,
                transction_type: null,
              },
            ],
          },
        ],
        deleted_packages: [],
        deleted_pricing: [],
        deleted_durations: [],
        deleted_benefits: [],
      },
      valid: true,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      packageTypes: [
        { type: "I", name: "Individual" },
        { type: "C", name: "Couple" },
        { type: "G", name: "Group" },
      ],
      btnShow: false,
      filterData: {},
      parentCarousels: [],
    };
  },

  watch: {
    membership: {
      handler: function(newData) {
        if (this.membership.description) {
          this.membership_desc = newData.description;
        }
        if (this.membership.ar_description) {
          this.ar_membership_desc = newData.ar_description;
        }
      },
      immediate: true,
    },
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    installmentPlans() {
      return this.$store.getters.getInstallmentPlans.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypesCustom("Voucher,Memberships");
    },
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
    isEnableDctErp() {
      if (
        this.$store.getters.userVenues &&
        this.$store.getters.userVenues.length > 0
      ) {
        return this.$store.getters.userVenues[0].enable_dct_erp;
      }
      return 0;
    },
  },
  methods: {
    setDescriptionContent(content) {
      if (this.membership) {
        this.membership.description = content;
      }
    },
    setArDescriptionContent(content) {
      if (this.membership) {
        this.membership.ar_description = content;
      }
    },
    pricingRemovePackage(dIndex, pIndex, priIndex) {
      // this.membership.durations[dIndex].packages[pIndex].pricing.splice(
      //   priIndex,
      //   1
      // );
      this.warningPricingRemove(dIndex, pIndex, priIndex);
    },
    calculateTaxChange(dIndex, pIndex, priIndex, amount, type) {
      let taxTypeId = this.membership.durations[dIndex].packages[pIndex]
        .tax_type_id;
      let taxPercentage = 0;
      if (taxTypeId) {
        taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
          .percentage;
      }
      const priceData = this.getTaxVariation(type, taxPercentage, amount);
      this.membership.durations[dIndex].packages[pIndex].pricing[
        priIndex
      ].pre_tax_price = priceData.price.toFixed(2);

      this.membership.durations[dIndex].packages[pIndex].pricing[
        priIndex
      ].total_price = priceData.total_price.toFixed(2);
    },
    updateAllPrices(dIndex, type = "post") {
      this.membership.durations[dIndex].packages.forEach((ele, ii) => {
        this.membership.durations[dIndex].packages[ii].pricing.forEach(
          (element, index) => {
            this.priceChange(dIndex, ii, index, type);
          }
        );
      });
    },

    priceChange(dIndex, pIndex, priIndex, type) {
      if (
        type == "post" &&
        this.membership.durations[dIndex].packages[pIndex].pricing[priIndex]
          .total_price
      ) {
        this.calculateTaxChange(
          dIndex,
          pIndex,
          priIndex,
          this.membership.durations[dIndex].packages[pIndex].pricing[priIndex]
            .total_price,
          type
        );
      } else if (
        type == "pre" &&
        this.membership.durations[dIndex].packages[pIndex].pricing[priIndex]
          .pre_tax_price
      ) {
        this.calculateTaxChange(
          dIndex,
          pIndex,
          priIndex,
          this.membership.durations[dIndex].packages[pIndex].pricing[priIndex]
            .pre_tax_price,
          type
        );
      }
      if (
        this.membership.durations[dIndex].packages[pIndex].pricing[priIndex]
          .installment_plan &&
        this.membership.durations[dIndex].packages[pIndex].pricing[priIndex]
          .total_price &&
        this.membership.durations[dIndex].packages[pIndex].pricing[priIndex]
          .pre_tax_price
      ) {
        let divisor = this.installmentPlans.filter((ele) => {
          return (
            ele.id ===
            this.membership.durations[dIndex].packages[pIndex].pricing[priIndex]
              .installment_plan
          );
        })[0];
        if (divisor.divisor > 1) {
          this.membership.durations[dIndex].packages[pIndex].pricing[
            priIndex
          ].price_per_installment =
            (this.membership.durations[dIndex].packages[pIndex].pricing[priIndex]
              .total_price / divisor.divisor)||0;
          // /
          // (this.membership.durations[dIndex].year
          //   ? this.membership.durations[dIndex].year
          //   : this.membership.durations[dIndex].month &&
          //     this.membership.durations[dIndex].month % 6 == 0
          //   ? this.membership.durations[dIndex].month / 6
          //   : 1);
        } else {
          this.membership.durations[dIndex].packages[pIndex].pricing[
            priIndex
          ].price_per_installment = this.membership.durations[dIndex].packages[
            pIndex
          ].pricing[priIndex].total_price || 0;
        }
      }
      this.$forceUpdate();
    },
    newPricing(dIndex, pIndex) {
      this.membership.durations[dIndex].packages[pIndex].pricing.push({
        installment_plan: null,
        tax_type_id: null,
        pre_tax_price: null,
        total_price: null,
      });
    },
    getStatus(index) {
      if (
        this.membership.durations[index].year &&
        !this.membership.durations[index].month &&
        !this.membership.durations[index].day
      ) {
        if (this.membership.durations[index].year % 1 == 0) {
          return true;
        } else {
          return false;
        }
      } else if (
        !this.membership.durations[index].year &&
        this.membership.durations[index].month &&
        !this.membership.durations[index].day
      ) {
        if (this.membership.durations[index].month % 6 == 0) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    getStatusDuration(duration) {
      if (duration.year && !duration.month && !duration.day) {
        if (duration.year % 1 == 0) {
          return true;
        } else {
          return false;
        }
      } else if (!duration.year && duration.month && !duration.day) {
        if (duration.month % 6 == 0) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    getInstallmentConfig(index) {
      if (
        this.membership.durations[index].year &&
        !this.membership.durations[index].month &&
        !this.membership.durations[index].day
      ) {
        if (this.membership.durations[index].year % 1 == 0) {
          return 12;
        } else {
          return false;
        }
      } else if (
        !this.membership.durations[index].year &&
        this.membership.durations[index].month &&
        !this.membership.durations[index].day
      ) {
        if (this.membership.durations[index].month % 6 == 0) {
          return 6;
        } else {
          return false;
        }
      }
      return false;
    },
    addPackage(di){
      this.membership.durations[di].packages.push({
        name: null,
        type: null,
        price: null,
        tax_type_id: null,
        benefits: [],
        allow_additional_promo: 1,
        pricing: [
          {
            installment_plan: 1,
            price_per_installment: null,
            pre_tax_price: null,
            total_price: null,
          },
        ],
      })
    },
    getInstallmentOptions(index) {
      if (this.getInstallmentConfig(index) == 6) {
        return this.installmentPlans.filter((ele) => {
          return [1, 2, 6].includes(ele.divisor);
        });
      } else if (this.getInstallmentConfig(index) == 12) {
        return this.installmentPlans;
      }
      // if not matching above, return 1 payment plan
      return this.installmentPlans.filter((ele) => {
        return [1].includes(ele.divisor);
      });
    },
    installmentPlansCheck(index) {
      let data = this.getStatus(index);

      this.membership.durations[index].installment_check = data;

      if (!data) {
        this.membership.durations[index].packages.forEach((ele, ii) => {
          this.membership.durations[index].packages[ii].pricing.splice(1);
          this.membership.durations[index].packages[
            ii
          ].pricing[0].installment_plan = 1;
        });
      } else {
        this.updateAllPrices(index);
      }
    },
    populateEditMembership(id) {
      if (!this.installmentPlans) {
        this.$store.dispatch("loadInstallmentPlans");
      }
      setTimeout(() => {
        this.showLoader("Loading");

        this.$http
          .get("venues/memberships/" + id)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.membership.deleted_pricing = [];
              this.membership.deleted_packages = [];
              this.membership.deleted_durations = [];
              this.membership.deleted_benefits = [];
              const data = response.data.data;
              this.membership.id = data.id;
              this.membership.name = data.name;
              if (data.ar_name) {
                this.membership.ar_name = data.ar_name;
              }
              this.membership.image_path = data.image_path;
              this.membership.card_image_path = data.card_image_path;
              this.membership.description = data.description;
              this.membership_desc = data.description;
              this.membership.ar_description = data.ar_description;
              this.ar_membership_desc = data.ar_description;
              this.membership.is_public = data.is_public;
              this.membership.start_date = data.start_date;
              this.membership.end_date = data.end_date;
              this.membership.has_date_restriction = data.has_date_restriction;
              this.membership.venue_service_id = data.venue_services.map(
                (item) => item.venue_service_id
              );
              this.membership.carousel_id = data.carousel_categories.map(
                (item) => item.parent_carousel_id
              );
              this.membership.durations = [];
              data.durations.forEach((duration) => {
                let durations = {
                  day: duration.day > 0 ? duration.day : null,
                  year: duration.year > 0 ? duration.year : null,
                  month: duration.month > 0 ? duration.month : null,
                  id: duration.id,
                  packages: [],
                  installment_check: this.getStatusDuration(duration),
                };
                duration.packages.forEach((packge) => {
                  let packages = {
                    name: packge.name,
                    ar_name: packge.ar_name || "",
                    membership_type: packge.membership_type,
                    id: packge.id,
                    price: packge.price,
                    pre_tax_price: packge.price,
                    total_price: packge.total_price,
                    product_id: packge.product_id,
                    additional_price: packge.additional_price,
                    members_limit: packge.members_limit,
                    additional_members:
                      packge.additional_price != null ? "Yes" : "No",
                    allow_additional_promo: packge.allow_additional_promo,
                    benefits: [],
                    pricing: [],
                    tax_type_id: packge.tax_type_id,
                  };

                  packge.pricing.forEach((ele) => {
                    let divisor = this.installmentPlans.filter((element) => {
                      return element.id === ele.installment_plan_id;
                    })[0];
                    let pricing = {
                      id: ele.id,
                      installment_plan: ele.installment_plan_id,
                      pre_tax_price: ele.price,
                      price_per_installment: (ele.total / divisor.divisor) || 0,
                      // /
                      // (duration.year > 0 && duration.year % 1 == 0
                      //   ? duration.year
                      //   : duration.month && duration.month % 6 == 0
                      //   ? duration.month / 6
                      //   : 1)
                      total_price: ele.total_price,
                    };
                    packages.name = ele.name;
                    packages.ar_name = ele.ar_name || "";
                    packages.tax_type_id = ele.tax_type_id;
                    (packages.project_no = ele.project_no),
                      (packages.task_name = ele.task_name),
                      (packages.gl_code = ele.gl_code),
                      (packages.transaction_type = ele.transaction_type),
                      packages.pricing.push(pricing);
                    if (!durations.installment_check) {
                      let deleting = packages.pricing.splice(1);
                      deleting.forEach((ele) => {
                        this.membership.deleted_pricing.push(ele.id);
                      });
                      packages.pricing.splice(1);
                    }
                  });

                  packge.benefits.forEach((element) => {
                    let benefit = {};
                    benefit.benefit_type = element.benefit_type;
                    benefit.benefit = element.benefit;
                    benefit.id = element.id;
                    benefit.is_specific = element.is_specific;
                    benefit.product_type = element.product_type;
                    benefit.product_type_id = element.product_type_id;
                    benefit.venue_service_ids = element.services.map(
                      (item) => item.venue_service_id
                    );
                    if (element.is_specific == 1) {
                      benefit.venue_service_ids = benefit.venue_service_ids[0];
                    }
                    benefit.category_ids = element.categories.map(
                      (item) => item.category_id
                    );
                    benefit.event_ids = element.events.map(
                      (item) => item.event_id
                    );
                    benefit.workshop_ids = element.workshops.map(
                      (item) => item.workshop_id
                    );
                    benefit.membership_ids = element.memberships.map(
                      (item) => item.membership_id
                    );
                    benefit.trainer_ids = element.trainers.map(
                      (item) => item.trainer_id
                    );
                    benefit.product_ids = element.products.map(
                      (item) => item.product_id
                    );
                    benefit.outlet_ids = element.outlets.map(
                      (item) => item.outlet_id
                    );
                    packages.benefits.push(benefit);
                  });
                  durations.packages.push(packages);
                });
                this.membership.durations.push(durations);
                this.updateAllPrices(this.membership.durations.length - 1);
              });
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      });
    },
    newDurationType() {
      this.membership.durations.push({
        year: null,
        month: null,
        day: null,
        tax_type_id: null,
        packages: [
          {
            name: null,
            type: null,
            price: null,
            benefits: [],
            pricing: [
              {
                installment_plan: 1,
                price_per_installment: null,
                pre_tax_price: null,
                total_price: null,
              },
            ],
          },
        ],
      });
    },
    addOrEditmembership() {
      if (!this.$refs.form.validate()) {
        this.showError("Please enter all required fields");
        return;
      }
      this.showLoader();
      let formData = new FormData();
      if (
        this.membership.image != null &&
        typeof this.membership.image != "string"
      )
        formData.append("image", this.membership.image);

      if (this.membership.file) {
        formData.append("card_image", this.membership.file);
      }
      formData.append("name", this.membership.name);
      if (this.membership.ar_name) {
        formData.append("ar_name", this.membership.ar_name);
      }
      if (this.membership.description) {
        formData.append("description", this.membership.description);
      }
      if (this.membership.ar_description) {
        formData.append("ar_description", this.membership.ar_description);
      }

      formData.append("has_date_restriction", this.membership.has_date_restriction);
      if(this.membership.start_date) {
        formData.append("start_date", this.membership.start_date);
      }
      if(this.membership.end_date) {
        formData.append("end_date", this.membership.end_date);
      }

      if (this.membership.venue_service_id.length > 0) {
        this.membership.venue_service_id.forEach((id, vi) => {
          formData.append(`venue_service_id[${vi}]`, id);
        });
      }
      if (this.membership.deleted_benefits.length > 0) {
        this.membership.deleted_benefits.forEach((id, index) => {
          formData.append(`deleted_benefits[${index}]`, id);
        });
      }
      if (this.membership.deleted_packages.length > 0) {
        this.membership.deleted_packages.forEach((id, index) => {
          formData.append(`deleted_packages[${index}]`, id);
        });
      }
      if (this.membership.deleted_durations.length > 0) {
        this.membership.deleted_durations.forEach((id, index) => {
          formData.append(`deleted_durations[${index}]`, id);
        });
      }
      if (this.membership.deleted_pricing.length > 0) {
        this.membership.deleted_pricing.forEach((id, index) => {
          formData.append(`deleted_pricing[${index}]`, id);
        });
      }
      if (this.membership.carousel_id.length > 0) {
        this.membership.carousel_id.forEach((id, index) => {
          formData.append(`carousel_id[${index}]`, id);
        });
      } else if (this.parentCarousels.length > 0) {
        formData.append(`carousel_id`, []);
      }

      this.membership.durations.forEach((duration, di) => {
        if (duration.id != null) {
          formData.append(`durations[${di}][id]`, duration.id);
        }
        if (duration.year != null) {
          formData.append(`durations[${di}][year]`, duration.year);
        }
        if (duration.month != null) {
          formData.append(`durations[${di}][month]`, duration.month);
        }
        if (duration.day != null) {
          formData.append(`durations[${di}][day]`, duration.day);
        }
        duration.packages.forEach((packge, pi) => {
          if (packge.id != null) {
            formData.append(`durations[${di}][packages][${pi}][id]`, packge.id);
            formData.append(
              `durations[${di}][packages][${pi}][product_id]`,
              packge.product_id
            );
          }
          formData.append(
            `durations[${di}][packages][${pi}][name]`,
            packge.name
          );
          if (packge.ar_name) {
            formData.append(
              `durations[${di}][packages][${pi}][ar_name]`,
              packge.ar_name
            );
          }
          formData.append(
            `durations[${di}][packages][${pi}][price]`,
            packge.price
          );
          formData.append(
            `durations[${di}][packages][${pi}][tax_type_id]`,
            packge.tax_type_id
          );
          formData.append(
            `durations[${di}][packages][${pi}][membership_type]`,
            packge.membership_type
          );

          if (packge.project_no != null) {
            formData.append(
              `durations[${di}][packages][${pi}][project_no]`,
              packge.project_no
            );
          }
          if (packge.task_name != null) {
            formData.append(
              `durations[${di}][packages][${pi}][task_name]`,
              packge.task_name
            );
          }
          if (packge.gl_code != null) {
            formData.append(
              `durations[${di}][packages][${pi}][gl_code]`,
              packge.gl_code
            );
          }
          if (packge.transaction_type != null) {
            formData.append(
              `durations[${di}][packages][${pi}][transaction_type]`,
              packge.transaction_type
            );
          }

          if (packge.members_limit != null) {
            formData.append(
              `durations[${di}][packages][${pi}][members_limit]`,
              packge.members_limit
            );
          }
          if (packge.additional_members == 'Yes' && packge.additional_price != null) {

            formData.append(
              `durations[${di}][packages][${pi}][additional_price]`,
              packge.additional_price
            );
          }
          if (packge.additional_members == 'Yes' && packge.allow_additional_promo != null) {
            formData.append(
              `durations[${di}][packages][${pi}][allow_additional_promo]`,
              packge.allow_additional_promo
            );
          }
          packge.pricing.forEach((pricing, pri) => {
            if (pricing.id) {
              formData.append(
                `durations[${di}][packages][${pi}][pricing][${pri}][id]`,
                pricing.id
              );
            }
            formData.append(
              `durations[${di}][packages][${pi}][pricing][${pri}][installment_plan]`,
              pricing.installment_plan
            );
            formData.append(
              `durations[${di}][packages][${pi}][pricing][${pri}][price_per_installment]`,
              pricing.price_per_installment
            );
            formData.append(
              `durations[${di}][packages][${pi}][pricing][${pri}][pre_tax_price]`,
              pricing.pre_tax_price
            );
            formData.append(
              `durations[${di}][packages][${pi}][pricing][${pri}][total_price]`,
              pricing.total_price
            );
          });
          packge.benefits.forEach((benefit, bi) => {
            if (benefit.id != null) {
              formData.append(
                `durations[${di}][packages][${pi}][benefits][${bi}][id]`,
                benefit.id
              );
            }
            formData.append(
              `durations[${di}][packages][${pi}][benefits][${bi}][product_type_id]`,
              benefit.product_type_id
            );
            formData.append(
              `durations[${di}][packages][${pi}][benefits][${bi}][is_specific]`,
              benefit.is_specific
            );
            formData.append(
              `durations[${di}][packages][${pi}][benefits][${bi}][benefit_type]`,
              benefit.benefit_type
            );
            formData.append(
              `durations[${di}][packages][${pi}][benefits][${bi}][benefit]`,
              benefit.benefit
            );
            if (benefit.is_specific == 1) {
              benefit.product_ids.forEach((id, i) => {
                formData.append(
                  `durations[${di}][packages][${pi}][benefits][${bi}][product_ids][${i}]`,
                  id
                );
              });
            }
            if (!Array.isArray(benefit.venue_service_ids)) {
              formData.append(
                `durations[${di}][packages][${pi}][benefits][${bi}][venue_service_ids][0]`,
                benefit.venue_service_ids
              );
            } else {
              benefit.venue_service_ids.forEach((id, vi) => {
                formData.append(
                  `durations[${di}][packages][${pi}][benefits][${bi}][venue_service_ids][${vi}]`,
                  id
                );
              });
            }
            if (
              benefit.category_ids != null &&
              benefit.category_ids.length > 0
            ) {
              benefit.category_ids.forEach((id, ci) => {
                formData.append(
                  `durations[${di}][packages][${pi}][benefits][${bi}][category_ids][${ci}]`,
                  id
                );
              });
            }
            if (benefit.event_ids != null && benefit.event_ids.length > 0) {
              benefit.event_ids.forEach((id, ei) => {
                formData.append(
                  `durations[${di}][packages][${pi}][benefits][${bi}][event_ids][${ei}]`,
                  id
                );
              });
            }
            if (
              benefit.workshop_ids != null &&
              benefit.workshop_ids.length > 0
            ) {
              benefit.workshop_ids.forEach((id, wi) => {
                formData.append(
                  `durations[${di}][packages][${pi}][benefits][${bi}][workshop_ids][${wi}]`,
                  id
                );
              });
            }
            if (benefit.trainer_ids != null && benefit.trainer_ids.length > 0) {
              benefit.trainer_ids.forEach((id, ti) => {
                formData.append(
                  `durations[${di}][packages][${pi}][benefits][${bi}][trainer_ids][${ti}]`,
                  id
                );
              });
            }
            if (benefit.outlet_ids != null && benefit.outlet_ids.length > 0) {
              benefit.outlet_ids.forEach((id, oi) => {
                formData.append(
                  `durations[${di}][packages][${pi}][benefits][${bi}][outlet_ids][${oi}]`,
                  id
                );
              });
            }
          });
        });
      });
      formData.append("is_public", this.membership.is_public);
      this.$http
        .post(
          `venues/memberships${
            this.membership.id != null ? "/" + this.membership.id : ""
          }`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          }
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let message =
              this.membership.id != null
                ? "Membership details updated successfully"
                : "Membership added successfully";
            this.showSuccess(message);
            this.$router.push({ name: "Memberships" });
            this.$store.dispatch("loadMemberships");
            this.addMembershipDialoge = false;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        })
        .finally(() => {
          this.hideLoader();
        });
    },
    warningPackageRemove(dIndex, pIndex) {
      let pkg = this.membership.durations[dIndex].packages[pIndex];
      if (
        pkg.name == null &&
        pkg.price == null &&
        pkg.type == null &&
        pkg.id == null
      ) {
        this.membership.durations[dIndex].packages.splice(pIndex, 1);
      } else {
        this.confirmModel = {
          id: pIndex,
          title: "Do you want to delete this package?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "package",
          data: { dIndex: dIndex, pIndex: pIndex },
        };
      }
    },
    warningDurationDelete(index) {
      this.confirmModel = {
        id: index,
        title: "Do you want to delete this duration type?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "duration",
      };
    },
    warningBenefitRemove(data) {
      let dIndex = data.durationIndex;
      let bIndex = data.benefitIndex;
      let pIndex = data.packageIndex;
      let benefit = this.membership.durations[dIndex].packages[pIndex].benefits[
        bIndex
      ];
      if (
        benefit.id == null &&
        benefit.venue_service_ids == null &&
        benefit.benefit_type == null &&
        benefit.benefit == null
      ) {
        this.membership.durations[dIndex].packages[pIndex].benefits.splice(
          bIndex,
          1
        );
      } else {
        this.confirmModel = {
          id: bIndex,
          title: "Do you want to delete this benefit?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "benefit",
          data: { dIndex: dIndex, pIndex: pIndex, bIndex: bIndex },
        };
      }
    },
    warningPricingRemove(dIndex, pIndex, priIndex) {
      let bIndex = priIndex;
      let pricing = this.membership.durations[dIndex].packages[pIndex].pricing[
        bIndex
      ];
      if (pricing.id == null) {
        this.membership.durations[dIndex].packages[pIndex].pricing.splice(
          bIndex,
          1
        );
      } else {
        this.confirmModel = {
          id: bIndex,
          title: "Do you want to delete this pricing?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "pricing",
          data: { dIndex: dIndex, pIndex: pIndex, bIndex: bIndex },
        };
      }
    },
    confirmActions(data) {
      if (data.type == "package") {
        let dIndex = data.data.dIndex;
        let pIndex = data.data.pIndex;
        let pkg = this.membership.durations[dIndex].packages[pIndex];
        if (pkg.id != null) {
          this.membership.deleted_packages.push(
            this.membership.durations[dIndex].packages[pIndex].id
          );
        }
        this.membership.durations[dIndex].packages.splice(pIndex, 1);
      } else if (data.type == "duration") {
        if (this.membership.durations[data.id].id != null) {
          this.membership.deleted_durations.push(
            this.membership.durations[data.id].id
          );
        }
        this.membership.durations.splice(data.id, 1);
      } else if (data.type == "pricing") {
        let pIndex = data.data.pIndex;
        let bIndex = data.data.bIndex;
        let dIndex = data.data.dIndex;
        let pricing = this.membership.durations[dIndex].packages[pIndex]
          .pricing[bIndex];
        if (pricing.id != null) {
          this.membership.deleted_pricing.push(pricing.id);
        }
        this.membership.durations[dIndex].packages[pIndex].pricing.splice(
          bIndex,
          1
        );
      } else if (data.type == "benefit") {
        let pIndex = data.data.pIndex;
        let bIndex = data.data.bIndex;
        let dIndex = data.data.dIndex;
        let benefit = this.membership.durations[dIndex].packages[pIndex]
          .benefits[bIndex];
        if (benefit.id != null) {
          this.membership.deleted_benefits.push(benefit.id);
        }
        this.membership.durations[dIndex].packages[pIndex].benefits.splice(
          bIndex,
          1
        );
      }
      this.$forceUpdate();
      this.confirmModel.id = null;
    },
    setPackageTypeChange(durIndex, pakIndex) {
      let pkgType = this.membership.durations[durIndex].packages[pakIndex]
        .membership_type;
      if (pkgType == "I") {
        this.membership.durations[durIndex].packages[
          pakIndex
        ].members_limit = 1;
        this.membership.durations[durIndex].packages[
          pakIndex
        ].additional_price = null;
      } else if (pkgType == "C") {
        this.membership.durations[durIndex].packages[
          pakIndex
        ].members_limit = 2;
        this.membership.durations[durIndex].packages[
          pakIndex
        ].additional_price = null;
      } else if (pkgType == "G") {
        this.membership.durations[durIndex].packages[
          pakIndex
        ].members_limit = null;
        this.membership.durations[durIndex].packages[
          pakIndex
        ].additional_price = null;
      }
    },
    addNewBenefit(indexes) {
      this.membership.durations[indexes.durationIndex].packages[
        indexes.packageIndex
      ].benefits.push({});
      this.$forceUpdate();
    },
    changeCategory(dIndex, pindex, bIndex) {
      let benefit = this.membership.durations[dIndex].packages[pindex].benefits[
        bIndex
      ];
      if (benefit.is_specific == 1) {
        this.getProductCategories(benefit.venueServices.id);
      }
    },
    addBenefitProducts(dIndex, pindex, bIndex) {
      this.membership.durations[dIndex].packages[pindex].benefits[
        bIndex
      ].benefit_products.push({
        product_category: null,
        products: [],
        benefit_type: null,
        benefit_amount: null,
      });
      this.$forceUpdate();
    },
    addProductType( dindex, pIndex) {
      let type = {...this.productTypes[0]};
      let data = {
        is_specific: 0,
        venue_service_ids: [],
        product_type: type,
        product_type_id: type.id,
      };
      if (type.name == "Academy" || type.name == "Event") {
        data.event_ids = [];
      } else if (type.name == "Facility") {
        data.category_ids = [];
      } else if (type.name == "Membership") {
        data.membership_ids = [];
      } else if (type.name == "Trainer") {
        data.trainer_ids = [];
      } else if (type.name == "Outlet") {
        data.outlet_ids = [];
      }
      this.membership.durations[dindex].packages[pIndex].benefits.push(data);
    },
    duplicateBenefit(data) {
      let dIndex = data.durationIndex;
      let bIndex = data.benefitIndex;
      let pIndex = data.packageIndex;
      let benefit = JSON.parse(
        JSON.stringify(
          this.membership.durations[dIndex].packages[pIndex].benefits[bIndex]
        )
      );
      delete benefit.id;
      let filter = [];
      if (this.filterData[dIndex]) {
        filter = this.filterData[dIndex][pIndex][bIndex];
      }

      this.membership.durations[dIndex].packages[pIndex].benefits.splice(
        bIndex,
        0,
        benefit
      );
      if (this.filterData[dIndex]) {
        this.filterData[dIndex][pIndex][bIndex + 1] = JSON.parse(
          JSON.stringify(filter)
        );
      }
    },
    gotoMembership() {
      this.$router.push({
        name: "Memberships",
      });
    },
    updateBenefit(data) {
      let dindex = data.index.durationIndex;
      let bIndex = data.index.benefitIndex;
      let pIndex = data.index.packageIndex;
      let field = data.field;
      if (pIndex != null && bIndex != null && dindex != null && data) {
        if(field === 'product_type_id'){
          let type= this.productTypes.find((p)=>{
            return p.id == data.data;
          })
          if(type){
            this.membership.durations[dindex].packages[pIndex].benefits[bIndex].product_type = type;
          }
        }
        this.membership.durations[dindex].packages[pIndex].benefits[bIndex][
          field
        ] = data.data;
      }
    },
    toggleSelect() {
      this.$nextTick(() => {
        if (
          this.venueServices.length == this.membership.venue_service_id.length
        ) {
          this.membership.venue_service_id = [];
        } else {
          this.membership.venue_service_id = this.venueServices.map(
            (item) => item.venue_service_id
          );
        }
      });
    },
    toggleSelectCarousel() {
      this.$nextTick(() => {
        if (this.parentCarousels.length == this.membership.carousel_id.length) {
          this.membership.carousel_id = [];
        } else {
          this.membership.carousel_id = this.parentCarousels.map(
            (item) => item.id
          );
        }
      });
    },
    getServiceIcon() {
      if (this.membership.venue_service_id.length == 0)
        return "mdi-checkbox-blank-outline";
      if (this.venueServices.length == this.membership.venue_service_id.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },
    calculateTaxVariation(dIndex, pIndex, amount, type) {
      let taxTypeId = this.membership.durations[dIndex].packages[pIndex]
        .tax_type_id;
      let taxPercentage = 0;
      if (taxTypeId) {
        taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
          .percentage;
      }
      const priceData = this.getTaxVariation(type, taxPercentage, amount);
      this.membership.durations[dIndex].packages[pIndex].price =
        priceData.price;
      this.membership.durations[dIndex].packages[
        pIndex
      ].pre_tax_price = priceData.price.toFixed(2);

      this.membership.durations[dIndex].packages[pIndex].total_price =
        priceData.total_price;
      this.$forceUpdate();
    },
    taxChange(dIndex, pIndex) {
      let type = "pre";
      if (this.membership.durations[dIndex].packages[pIndex].price) {
        this.calculateTaxVariation(
          dIndex,
          pIndex,
          this.membership.durations[dIndex].packages[pIndex].price,
          "pre"
        );
        type = "pre";
      } else if (
        this.membership.durations[dIndex].packages[pIndex].total_price
      ) {
        this.calculateTaxVariation(
          dIndex,
          pIndex,
          this.membership.durations[dIndex].packages[pIndex].total_price,
          "post"
        );
        type = "post";
      }
      this.updateAllPrices(dIndex, type);
    },
    loadParentCarousels() {
      this.showLoader("Loading");
      this.$http
        .get(`venues/memberships/carousels`)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.parentCarousels = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        })
        .finally(() => {
          this.hideLoader();
        });
    },
  },
};
</script>

<style scoped>
.product_add_btn {
  margin: 0 58%;
}
.p-plan {
  padding: 10px;
  border: 1px solid #ccc;
}


label{
  color:#565C68 !important;
  font-weight: 500;
}

@media screen and (min-width: 1264px) and (max-width: 1406px) {
  .form_container {
    max-width: 100% !important;
  }
  .v-input{
    font-size: 12px;
  }
}

</style>
